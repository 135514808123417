import { createRouter, createWebHistory } from 'vue-router'
const Home = () => import('@/views/Home')

const routes = [
  {
    path: '',
    redirect:'/home'
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      title: '人事招聘结果查询'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  next();
  document.title = to.meta.title;
})

export default router
